import styled from 'styled-components';
import { Link } from 'gatsby';

export const TryRecipe = styled.div`
  font-family: Caveat;
  text-align: center;
  font-size: 28px;
  margin-bottom: 25px;
  margin-top: 30px;

  .emoji {
    display: inline-block;
    margin: 0 0 0 10px;
    vertical-align: middle;
  }
`;

export const Title = styled.div`
  font-family: Muli;
  font-size: 52px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  color: #faab06;
  margin-top: 70px;
`;

export const Date = styled.div`
  font-size: 13px;
  color: #bbb;
  text-align: center;
  margin-top: 25px;
`;

export const Author = styled.div`
  text-align: center;
  margin-top: 20px;

  img {
    width: 60px;
    border-radius: 250px;
    border: 1px #ddd solid;
    padding: 3px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }

  h3 {
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 10px;
  }
`;

export const Post = styled.div`
  font-size: 19px;
  line-height: 1.6;
  text-align: left;

  blockquote {
    border-left: 3px #ddd solid;
    margin: 0;
    padding: 20px 30px;
    font-style: italic;
    font-family: Palatino;
    font-size: 19px;
  }
`;

export const Back = styled(Link)`
  background: #faab06;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -.5px;
  padding: 15px 30px;
  border-radius: 6px;
`;

export const FacebookShareContainer = styled.div`
  text-align: center;
  margin: 50px auto 0;
  display: inline-block;
  cursor: pointer;
  width: 100%;

  .icon {
    svg {
      color: #4167b2;
      vertical-align: middle;
      margin: -8px 5px 0 0;
    }
  }
`;
