import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col } from 'react-flexbox-grid';
// import Disqus from 'disqus-react';
import Confetti from 'react-confetti';
import { FacebookShareButton } from 'react-share';
import { Facebook } from 'react-feather';
import moment from 'moment';
import 'moment/locale/pt';


import Layout from '../../components/Layout';
import Header from '../../components/Header';
import Breaker from '../../components/Breaker';
import SEO from '../../components/Seo';
import { RECIPES_PATH } from '../../constants/Routes';

import { TryRecipe, Title, Date, Author, Post, Back, FacebookShareContainer } from './style';

const confettiConfig = {
  width: typeof window !== 'undefined' && window.innerWidth,
  height: typeof window !== 'undefined' && window.innerHeight,
  numberOfPieces: 50,
  recycle: false,
  opacity: .5
};

const Recipe = ({ data, location: { hash } }) => {
  const post = data.markdownRemark

  const isSharingRecipe = () => {
    return hash && hash === '#share-tracking'
  }

  // const disqusConfig = {
  //   url: typeof window !== 'undefined' && window.location.href,
  //   identifier: post.id,
  //   title: post.frontmatter.title,
  // };

	const facebookShareUrl = typeof window !== 'undefined' && window.location.href

  return (
    <Layout>
      <SEO
				title={post.frontmatter.title}
				keywords={
          (post.frontmatter.keywords || []).concat([
            `paleo`, `receita`, `caverna`, `wellness`, `fitness`, `dieta`, `health`, `formadora`, `coach`, `coaching`
          ])
        }
				facebookType="article"
				facebookUrl={facebookShareUrl}
				facebookTitle={post.frontmatter.title}
				facebookDescription={post.excerpt}
				facebookImage={post.frontmatter.cover}
			/>
      <Header />

      <Grid>
        <Row>
          <Col md={12}>
            {isSharingRecipe(post.fields.slug) && (
              <div>
                <Confetti {...confettiConfig} />

                <TryRecipe>
                  <span className="text-highlight">
                    Experimenta esta receita deliciosa que eu encontrei
                    <span className="emoji" role="img" aria-label="emoji">
                      🥰
                    </span>
                  </span>
                </TryRecipe>
              </div>
            )}

            <Title>
              {post.frontmatter.title}
            </Title>

            <Date>
              {moment(post.frontmatter.date).format('D MMMM YYYY')}
            </Date>

            <Author>
              <img src={post.frontmatter.author.avatar} alt={post.frontmatter.author.name} />
              <h3>{post.frontmatter.author.name}</h3>
            </Author>
          </Col>
        </Row>

        <Breaker />

        <Row center="md">
          <Col md={8}>
            <Post className="post-content" dangerouslySetInnerHTML={{ __html: post.html }} />
          </Col>
        </Row>

        <FacebookShareContainer>
          <FacebookShareButton url={facebookShareUrl} className="button is-outlined is-rounded facebook">
            <span className="icon">
							<Facebook />
            </span>
            <span className="text">Partilhe no Facebook</span>
          </FacebookShareButton>
        </FacebookShareContainer>

        <Breaker />

        <Row center="xs">
          <Col md={12}>
            <Back to={`${RECIPES_PATH}/?coming-back#${post.fields.slug.split('/')[2]}`}>
              Voltar para receitas
            </Back>
          </Col>
        </Row>

        <Row center="xs">
          <Col md={8}>
            <div style={{ marginTop: 100 }}>
              {/* <Disqus.DiscussionEmbed shortname={`nacavernadamarta`} config={disqusConfig} /> */}
            </div>
          </Col>
        </Row>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        author {
          name
          avatar
        }
				cover
        keywords
      }
      fields {
        slug
      }
      excerpt
    }
  }
`

export default Recipe;
